<template>
  <div class="facephoto-container">
    <EBackTop
      @goBack="leftArrowBack"
      :hasSuperiorClick="true"
      :background="'transparent'"
      class="mine-head"
      color="#fff"
      title="AI换脸"
    >
      <template #title>
        <span>AI换脸</span>
        <span :class="{ 'is-photo': !isVideo }"> ({{ !isVideo ? '图片' : '视频' }}) </span>
      </template>
      <template #right>
        <van-icon name="wap-nav" color="#ffffff" size="25" @click="isShowPop = !isShowPop" />
      </template>
    </EBackTop>
    <PullUp
      @refreshData="refreshData"
      @moreData="moreData"
      :finished="finished"
      :loading="loading"
      :refreshing="refreshing"
    >
      <div class="top-choose">
        <img src="@/assets/imgs/ai/pen.png" alt="" />
        <div>请在下方选择一个模版开始</div>
        <div>
          <img src="@/assets/imgs/ai/warning.svg" alt="" />
          禁止使用未成年人模版 (后台检测封号)
        </div>
      </div>
      <div class="modal">
        <div class="modal-left">
          <div></div>
          <div>
            <span>{{ !isVideo ? '图片' : '视频' }}模版</span>
            <div v-if="!isVideo">
              <span> (每个模板需</span>
              <img src="@/assets/imgs/coin2.png" alt="" />
              <span>8 金币)</span>
            </div>
          </div>
        </div>
        <div class="modal-right">
          <div :class="{ active: activeIndex == 1 }" @click="handleClick(1)">最新</div>
          <img src="@/assets/imgs/aiLab/Line.svg" alt="" />
          <div :class="{ active: activeIndex == 2 }" @click="handleClick(2)">热度</div>
        </div>
      </div>
      <WaterfallList
        @toDetail="toDetail"
        v-on:calcImgLoad="calcImgLoad"
        :datasList="listData"
        @fn_play_video="fn_play_video"
        :isVideo="isVideo"
      ></WaterfallList>
    </PullUp>
    <ComPlayVideo ref="ComPlayVideo" />
    <ChangeFace :isShow="isShowPop" @get_new_list="get_new_list" />
  </div>
</template>

<script>
import { aitemplate_list } from '@/api/ai'
export default {
  name: 'facePhoto',
  components: {
    PullUp: () => import('@/components/PullUp.vue'),
    WaterfallList: () => import('@/components/WaterfallList/index.vue'),
    ComPlayVideo: () => import('@/components/aiLab/video.vue'),
    ChangeFace: () => import('../components/leftPopup.vue')
  },
  data() {
    return {
      pageNum: 1,
      pageSize: 10,
      loading: false,
      refreshing: false,
      listData: [],
      finished: false, //
      activeIndex: 1,
      isShowPop: false,
      contentType: 1
    }
  },
  mounted() {
    this.refreshData()
  },
  computed: {
    isVideo() {
      return this.$route.query.type == 'video'
    }
  },
  methods: {
    leftArrowBack() {
      this.$router.push({
        path: '/ai/generate'
      })
    },
    async get_new_list() {
      this.isShowPop = false
      await this.resetAllParams()
      setTimeout(() => {
        this.refreshData(true)
      }, 0)
    },
    fn_play_video(item) {
      
      this.$refs.ComPlayVideo.fn_playVideo(item.videoUrl, item.videoCover)
    },
    refreshData(refreshing) {
      this.refreshing = refreshing
      this.resetAllParams()
      setTimeout(() => {
        this.getList('pull')
      }, 0)
    },
    moreData(loading) {
      console.log('loading', loading)
      this.loading = loading
      this.pageNum += 1
      this.getList()
    },
    async getList(item) {
      try {
        const res = await aitemplate_list({
          atType: 1,
          contentType: this.contentType,
          pageNum: this.pageNum,
          pageSize: this.pageSize,
          sort: this.activeIndex
        })

        if (res.code === 200) {
          this.refreshing = false
          this.loading = false
          this.listData = [...this.listData, ...res.data.list]
          if (res.data && res.data.list && res.data.list.length < this.pageSize) this.finished = true
        }
      } catch (error) {
        this.loading = false
        this.refreshing = false
        this.finished = true
        this.$toast('请求错误，请稍后再试！')
      }
    },
    // 跳转
    toDetail(item) {
      this.$store.commit('setChangeFaceItem', item)
      this.$router.push({
        path: `/ai/changeFace`,
        query: {
          ...this.$route.query,
          id: item.id
        }
      })
    },
    //是否允许上拉
    calcImgLoad(bool) {
      // this.loading = false
    },
    handleClick(index) {
      this.activeIndex = index
      this.resetAllParams()
      this.refreshData()
    },
    resetAllParams() {
      this.pageNum = 1
      this.finished = false
      this.loading = true
      this.contentType = this.$route.query.type == 'video' ? 2 : 1
      this.listData = []
    }
  }
}
</script>

<style lang="scss" scoped>
.facephoto-container {
  // padding: 0 0.36rem;
  background: #222;
  min-height: 100vh;
  :deep() .mine-head {
    .blue-title {
      // color: #00f6f8 !important;
      font-family: PingFang SC;
      font-size: 0.34rem;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
    .van-nav-bar__title {
      // color: #00f6f8;
      > span {
        &:nth-child(2) {
          color: #00f6f8;
          &.is-photo {
            color: #00f6f8;
          }
        }
      }
    }
  }

  .top-choose {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 0.71rem;

    > img {
      width: 0.8rem;
      height: 0.8rem;
    }

    > div {
      &:nth-of-type(1) {
        padding: 0.12rem 0.32rem;
        border-radius: 0.08rem;
        border: 1px solid #00f6f8;
        color: #00f6f8;
        font-family: PingFang SC;
        font-size: 0.24rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin: 0.36rem 0 0.27rem;
      }

      &:nth-of-type(2) {
        color: #ff2323;
        font-family: PingFang HK;
        font-size: 0.22rem;
        font-style: normal;
        font-weight: 400;
        line-height: 0.2rem;
        /* 90.909% */
        display: flex;
        align-items: center;

        > img {
          height: 0.22rem;
          margin-right: 0.12rem;
        }
      }
    }
  }

  .right-my-generate {
    padding: 0.08rem 0.16rem;
    border-radius: 0.64rem;
    background: #00f6f8;
    display: flex;
    align-items: center;
    color: #333;
    font-family: PingFang SC;
    font-size: 0.26rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

    img {
      width: 0.26rem;
      margin-right: 0.06rem;
    }
  }

  .modal {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 0.36rem;
  }

  .modal-left {
    display: flex;
    align-items: center;

    > div {
      &:nth-child(1) {
        width: 0.12rem;
        height: 0.25rem;
        border-radius: 0.12rem;
        background: #00f6f8;
        margin-right: 0.16rem;
      }

      &:nth-child(2) {
        display: flex;
        align-items: center;

        img {
          width: 0.24rem;
          vertical-align: middle;
          margin: 0 0.13rem 0 0.07rem;
        }

        > span {
          color: #fff;
          font-family: PingFang SC;
          font-size: 0.34rem;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
        }

        > div {
          display: flex;
          align-items: center;
          margin-left: 0.2rem;

          > span {
            color: #ffcf86;
            font-family: PingFang SC;
            font-size: 0.26rem;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
          }
        }
      }
    }
  }

  .modal-right {
    display: flex;
    align-items: center;

    > div {
      color: #fff;
      font-family: PingFang SC;
      font-size: 0.26rem;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      transition: all 0.5s;

      &.active {
        color: #00f6f8;
        font-family: PingFang SC;
        font-size: 0.26rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
    }

    > img {
      width: 0.04rem;
      margin: 0 0.16rem;
    }
  }
}
</style>