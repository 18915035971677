<template>
  <div class="global-uploader">
    <video v-if="showVideo" class="showVideo" :src="locationVideoUrl" controls></video>
    <span v-if="showVideo" class="tip_txt">*大小不超过10M</span>
    <!-- <van-icon   size="18" color="#000" name="clear" /> -->
    <img src="@/assets/imgs/trade/close.png" v-if="showVideo" class="colse" @click.stop="colse" alt="" />
    <uploader
      ref="videoUploader"
      :options="options"
      :autoStart="false"
      @file-added="onFileAdded"
      @file-success="onFileSuccess"
      @file-progress="onFileProgress"
      @file-error="onFileError"
      class="uploader-app"
    >
      <uploader-unsupport></uploader-unsupport>
      <uploader-btn :attrs="attrs" v-if="!showVideo">
        <van-loading v-if="videoLoading" type="spinner" class="loading-txt" />
        <div class="upload-img-item">
          <img src="@/assets/imgs/trade/upload_video.png" alt="" />
        </div>
      </uploader-btn>

      <!-- <uploader-list>
        <div class="file-panel" slot-scope="props" :class="{ collapse: collapse }">
          <ul class="file-list">
            <li v-for="file in props.fileList" :key="file.id">
              <uploader-file :class="'file_' + file.id" ref="files" :file="file" :list="true"></uploader-file>
            </li>
          </ul>
        </div>
      </uploader-list> -->
    </uploader>
  </div>
</template>

<script>
import SparkMD5 from 'spark-md5'
import { decodeDataToHttpResponse } from '@/utils/utils_tools'

export default {
  components: {},
  data() {
    return {
      showVideo: false,
      locationVideoUrl: '',
      videoLoading: false,
      collapse: false,
      options: {
        method: 'application/json',
        chunkSize: 1024 * 1024 * 5, // 切片大小
        testChunks: false, // 是否测试后端已有切片
        forceChunkSize: true, // 强制小于标准大小
        maxChunkRetries: 10, // 最大失败重试上传次数
        chunkRetryInterval: 20000, // 重试间隔
        simultaneousUploads: 8, // 并发上传数量
        singleFile: true, // 是否只能选择单项
        initialPaused: true, // 初始化暂停播放
        allowDuplicateUploads: true,
        progressCallbacksInterval: 500, // 时间间隔
        speedSmoothingFactor: 0.02, // 用于计算平均速度的参数
        target: '/api/app/vid/h5upload',
        checkChunkUploadedByResponse: function (chunk, message) {
          const objMessage = JSON.parse(message)
          if (objMessage.skipUpload) {
            return true
          }
          return (objMessage.uploaded || []).indexOf(chunk.offset + 1) >= 0
        },
        headers: {
          Authorization: this.$store.getters['getToken']
        },
        query: this.uploadQuery
      },
      // mp4类型
      attrs: {
        accept: ['video/*']
      },
      upLoadImgs: [] // 已上传图片
    }
  },

  methods: {
    // 取消上传
    colse() {
      this.showVideo = false
      this.$emit('result', false)
    },
    // ~~~~~~~~~~~~~上传视频start~~~~~~~~~~~~~~~~~~~~~~~
    // 添加视频第一步
    onFileAdded(file) {
      if (file.getSize() > 100 * 1024 * 1024) {
        file.cancel()
        this.$toast('视频不能超过100M')
        return false
      }

      this.locationVideoUrl = this.getObjectURL(file)
      this.videoLoading = true
      const fileReader = new FileReader()
      const time = new Date().getTime()
      const blobSlice = File.prototype.slice || File.prototype.mozSlice || File.prototype.webkitSlice
      let currentChunk = 0
      const chunkSize = 10 * 1024 * 1000
      const chunks = Math.ceil(file.size / chunkSize)
      const spark = new SparkMD5.ArrayBuffer()

      // 文件状态设为"计算MD5"
      this.statusSet(file.id, 'md5')
      file.pause()
      loadNext()
      fileReader.onload = e => {
        spark.append(e.target.result)
        if (currentChunk < chunks) {
          currentChunk++
          loadNext()
          console.log('校验MD5 ' + ((currentChunk / chunks) * 100).toFixed(0) + '%')
        } else {
          this.taskID = spark.end()
          this.$toast('开始上传，请等待')
          file.resume()
          console.log(
            `MD5计算完毕：${file.name} \nMD5：${spark} \n分片：${chunks} 大小:${file.size} 用时：${
              new Date().getTime() - time
            } ms`
          )
        }
      }

      fileReader.onerror = function () {
        this.$toast(`文件${file.name}读取出错，请检查该文件`)
        file.cancel()
      }

      function loadNext() {
        const start = currentChunk * chunkSize
        const end = start + chunkSize >= file.size ? file.size : start + chunkSize
        fileReader.readAsArrayBuffer(blobSlice.call(file.file, start, end))
      }
    },
    // 本地视频播放
    getObjectURL(file) {
      var url = null
      if (window.createObjectURL !== undefined) {
        // basic
        url = window.createObjectURL(file.file)
      } else if (window.URL !== undefined) {
        // mozilla(firefox)
        url = window.URL.createObjectURL(file.file)
      } else if (window.webkitURL !== undefined) {
        // webkit or chrome
        url = window.webkitURL.createObjectURL(file.file)
      }
      return url
    },
    // 上传中
    onFileProgress(rootFile, file, chunk) {
      console.log(`上传中 ${file.name}，chunk：${chunk.startByte / 1024 / 1024} ~ ${chunk.endByte / 1024 / 1024}`)
    },
    // 上传成功
    onFileSuccess(rootFile, file, response, chunk) {
      this.videoLoading = false
      const res = JSON.parse(response)
      if (res.code == 200) {
        this.showVideo = true // 显示本地视频
        let a = decodeDataToHttpResponse(res)
        this.video = res.data.videoUri
        res.data.taskID = this.taskID
        this.$emit('result', res.data)
        this.$toast('上传成功！！！')
      } else {
        if(res.code===4000){
          this.$toast('视频重复，请重新上传！')
        }else{
          this.$toast(res.tip)
        }
        
      }
    },
    // 上传失败
    onFileError(rootFile, file, response, chunk) {},
    // 设置状态目前没见有啥用
    statusSet(id, status) {
      const statusMap = {
        md5: {
          text: '校验MD5',
          bgc: '#fff'
        },
        merging: {
          text: '合并中',
          bgc: '#e2eeff'
        },
        transcoding: {
          text: '转码中',
          bgc: '#e2eeff'
        },
        failed: {
          text: '上传失败',
          bgc: '#e2eeff'
        }
      }
    },
    // 请求参数设置
    uploadQuery(file, chunk, v) {
      return {
        taskId: this.taskID, // taskId
        pos: chunk.offset, // 分片序号
        data: 'file',
        type: 1,
      }
    },
    clearInfo() {
      this.locationVideoUrl = ''
      this.showVideo = false
      this.upLoadImgs = []
    }
  },
  mounted() {}
}
</script>

<style lang="scss" scoped>
.global-uploader {
  position: relative;

  .uploader-btn {
    width: 1.6rem;
    height: 1.6rem;
    padding: 0;
    border: 0;
    position: relative;
  }

  :deep() {
    .van-loading--spinner {
      position: absolute;
      width: 100%;
      top: 0;
      left: 0;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}

.upload-img-item {
  width: 1.6rem;
  height: 1.6rem;

  img {
    width: 1.6rem;
    height: 1.6rem;
  }
}

.showVideo {
  width: 100%;
  height: 3rem;
}

.colse {
  position: absolute;
  top: 0rem;
  right: 0rem;
  width: 0.5rem;
  height: 0.5rem;
}
.tip_txt {
  color: #f9c006;
  font-size: 0.26rem;
}
</style>
